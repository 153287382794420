import axios from "axios"
import accessToken from "./jwt-token-access/accessToken"
import { store } from "storeRtk"
//pass new generated access token here
// const token = localStorage.getItem("jwt_token")

//apply base url for axios
const API_URL = process.env.REACT_APP_API

const axiosApi = axios.create({
  baseURL: API_URL,
})

export const getToken = () => {
  const state = store.getState()
  axiosApi.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${state?.authReduxSlice?.token}`
}

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}, rejectWithValue) {
  // console.log("TRIGGERED 1st GET (api_helper) autoLogin ", token)
  getToken() // re-run to set Authorization token
  return await axiosApi
    .get(url, { ...config })
    .then(response => response.data)
    .catch(error => rejectWithValue(error.response.data))
}

export async function post(url, data, config = {}, rejectWithValue) {
  getToken() // re-run to set Authorization token
  return axiosApi
    .post(url, data, config)
    .then(response => response.data)
    .catch(error => rejectWithValue(error.response.data))
}

export async function put(url, data, config = {}, rejectWithValue) {
  getToken() // re-run to set Authorization token
  return axiosApi
    .put(url, data, config)
    .then(response => response.data)
    .catch(error => rejectWithValue(error.response.data))
}

export async function del(url, config = {}, rejectWithValue) {
  getToken() // re-run to set Authorization token
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
    .catch(error => rejectWithValue(error.response.data))
}
