import { del, get, post, put } from "helpers/api_helper"

const PRODUCTS_BASE_URL = "/products?sort_by=desc"
const ADMIN_PRODUCT_STATUS_UPDATE = "/admin/update/status/product"

export const getProductsService = (params, rejectWithValue) =>
  get(
    PRODUCTS_BASE_URL,
    {
      params: params,
    },
    rejectWithValue
  )

export const getProductService = (id, params = {}, rejectWithValue) =>
  get(
    `${PRODUCTS_BASE_URL}/${id}`,
    {
      params: params,
    },
    rejectWithValue
  )

export const updateStatusProductService = (
  id,
  data,
  rejectWithValue,
  config = {}
) => post(`${ADMIN_PRODUCT_STATUS_UPDATE}/${id}`, data, config, rejectWithValue)
