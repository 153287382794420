import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import withRouter from "components/Common/withRouter"

//i18n
import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"

import { Link } from "react-router-dom"

import logo from "../../assets/images/logo.svg"
import logoLightPng from "../../assets/images/logo-light.png"
import logoLightSvg from "../../assets/images/logo-light.svg"
// import logoDark from "../../assets/images/logo-dark.png"
import philgepsLogo from "../../assets/images/philgeps-logo.png"

// REDUX
import { useSelector, useDispatch } from "storeRtk"

const role = JSON.parse(localStorage.getItem("authUser"))?.role_type

const Sidebar = props => {
  const { userData } = useSelector(state => state.authReduxSlice)

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <NavBarBrandBox className="navbar-brand-box">
          {userData?.role_type === "Merchant" ? (
            <Link to={`/merchant/dashboard`} className="logo logo-dark">
              <span className="logo-sm">
                <img src={philgepsLogo} alt="" height="22" />
              </span>
              <span className="logo-lg">
                <LogoWrapper>
                  <img src={philgepsLogo} alt="" height="46" width="84" />
                  <StyledH3>PS-PhilGEPS</StyledH3>
                </LogoWrapper>
              </span>
            </Link>
          ) : userData?.role_type === "Admin" ? (
            <Link to={`/admin/dashboard`} className="logo logo-dark">
              <span className="logo-sm">
                <img src={philgepsLogo} alt="" height="22" />
              </span>
              <span className="logo-lg">
                <LogoWrapper>
                  <img src={philgepsLogo} alt="" height="46" width="84" />
                  <StyledH3>PS-PhilGEPS</StyledH3>
                </LogoWrapper>
              </span>
            </Link>
          ) : (
            // <a
            //   href={`${process.env.REACT_APP_PHILGEPS_BASE_URL}/pending-tasks/pending-task-grid`}
            //   className="logo logo-dark"
            // >
            //   <span className="logo-sm">
            //     <img src={philgepsLogo} alt="" height="22" />
            //   </span>
            //   <span className="logo-lg">
            //     <LogoWrapper>
            //       <img src={philgepsLogo} alt="" height="46" width="84" />
            //       <StyledH3>PS-PhilGEPS</StyledH3>
            //     </LogoWrapper>
            //   </span>
            // </a>
            <Link
              to={`/${userData?.role_type?.toLowerCase()}/dashboard`}
              className="logo logo-dark"
            >
              <span className="logo-sm">
                <img src={philgepsLogo} alt="" height="22" />
              </span>
              <span className="logo-lg">
                <LogoWrapper>
                  <img src={philgepsLogo} alt="" height="46" width="84" />
                  <StyledH3>PS-PhilGEPS</StyledH3>
                </LogoWrapper>
              </span>
            </Link>
          )}

          {/* <Link
            to={`/`}
            className="logo logo-light"
            style={{ color: "#fff", fontSize: 17 }}
          > */}
          <a
            href={`${process.env.REACT_APP_PHILGEPS_BASE_URL}/pending-tasks/pending-task-grid`}
            className="logo logo-light"
            style={{ color: "#fff", fontSize: 17 }}
          >
            <span className="logo-sm">
              <img src={logo} alt="" height="22" />
            </span>
            <span className="logo-lg">
              <LogoWrapper>
                <img src={philgepsLogo} alt="" height="46" width="84" />
                <StyledH3>PS-PhilGEPS</StyledH3>
              </LogoWrapper>
            </span>
          </a>
          {/* </Link> */}
        </NavBarBrandBox>
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="sidebar-background">{""}</div>
      </div>
    </React.Fragment>
  )
}

const NavBarBrandBox = styled.div`
  padding: 1rem 1rem;
`

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StyledH3 = styled.h3`
  font-size: 20px;
  color: #212121;
`

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)))
