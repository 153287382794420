import { get, del, post, put } from "helpers/api_helper"

const AGENCY_GET_NOTIF_URL = "/notifications?include[]=user"
const AGENCY_PUT_NOTIF_URL = "/notifications"

const MERCHANT_GET_NOTIF_URL = "/notifications"
const MERCHANT_PUT_NOTIF_URL = "/notifications"

// AGENCY
export const getNotificationService = query => get(AGENCY_GET_NOTIF_URL, query)

export const updateNotificationService = (
  notificationId,
  data,
  rejectWithValue,
  config = {}
) =>
  put(
    `${AGENCY_PUT_NOTIF_URL}/${notificationId}`,
    data,
    config,
    rejectWithValue
  )

// MERCHANT
export const getMerchantNotificationService = query =>
  get(MERCHANT_GET_NOTIF_URL, query)

export const updateMerchantNotificationService = (
  notificationId,
  data,
  rejectWithValue,
  config = {}
) =>
  put(
    `${MERCHANT_PUT_NOTIF_URL}/${notificationId}`,
    data,
    config,
    rejectWithValue
  )
