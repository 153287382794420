import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import {
  getProductService,
  getProductsService,
  updateStatusProductService,
} from "services/admin_product.service"

import { transformData } from "helpers/dataTransformer"

export const getProducts = createAsyncThunk(
  "/getProducts",
  async (params, { rejectWithValue }) => {
    return await getProductsService(params, rejectWithValue)
  }
)

export const getProduct = createAsyncThunk(
  "/getProduct",
  async ({ id, params = {} }, { rejectWithValue }) => {
    return await getProductService(id, params, rejectWithValue)
  }
)

export const updateProductStatus = createAsyncThunk(
  "/updateProductStatus",
  async ({ data, id, config = {} }, { rejectWithValue }) => {
    return await updateStatusProductService(id, data, rejectWithValue, config)
  }
)

const initialLog = {
  type: "",
  error: false,
  message: null,
  loading: false,
  success: false,
}

const initialState = {
  products: [],
  product: {},
  included: [],
  logs: initialLog,
}

const slice = createSlice({
  name: "AdminProduct",
  initialState,
  reducers: {
    handleToggleProductActionType(state, action) {
      state.actionType = action.payload
    },
  },
  extraReducers: {
    [getProducts.pending]: state => {
      state.logs = { ...initialLog, loading: true, type: "getProducts" }
      state.products = []
    },
    [getProducts.fulfilled]: (state, action) => {
      state.products = transformData(action.payload)
      state.included = action.payload.result.included
      state.logs = { ...state.logs, success: true, loading: false }
    },
    [getProducts.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
        message: action?.payload?.errors ? (
          <>
            <p style={{ fontWeight: 600 }}>{"Loading Table Data Failed. "}</p>
            <p>{`${action?.payload?.errors[0]?.code}. ${action?.payload?.errors[0]?.detail}`}</p>
          </>
        ) : action?.payload === undefined ? (
          <>
            <p style={{ fontWeight: 600 }}>{"Loading Table Data Failed. "}</p>
            <p>{"404/500/503. Server Error or Resource not found due to API response undefined."}</p>
          </>
        ) : (
          "Something went wrong"
        ),
      }
    },
    [updateProductStatus.pending]: state => {
      state.logs = { ...initialLog, loading: true, type: "updateProductStatus" }
    },
    [updateProductStatus.fulfilled]: (state, action) => {
      state.logs = {
        ...state.logs,
        success: true,
        loading: false,
      }
    },
    [updateProductStatus.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
        message: action?.payload?.errors
          ? action?.payload?.errors[0]?.detail
          :  "Something went wrong",
      }
    },
    [getProduct.pending]: state => {
      state.logs = { ...initialLog, loading: true, type: "getProduct" }
    },
    [getProduct.fulfilled]: (state, action) => {
      state.product = action.payload.result.data
      state.included = action.payload.result?.included
      state.logs = { ...state.logs, success: true, loading: false }
    },
    [getProduct.rejected]: state => {
      state.logs = {
        ...state.logs,
        success: true,
        loading: false,
      }
    },
    ["PURGE"]: () => {
      return initialState
    },
  },
})

export const { actions: adminProductActions, reducer: adminProductReducer } =
  slice

export const { resetData } = adminProductActions

export default slice
