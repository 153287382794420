import { del, get, post, put } from "helpers/api_helper"

const CART = "/cart"
const ORDER = "/order"
const ORDERITEM = "/order-item"
const PRODUCTS_BASE_URL = "/orders"
const SHIPPING_ADDRESS = "/shipping_address"
const LIST_DISBURSEMENT = "/order/list-disbursement"
const DISBURSEMENT = "/disbursement"

export const updateOrderAddressService = (data, rejectWithValue, config = {}) =>
  post(
    `${ORDER}/update-delivery-option/${data?.id}?include[]=order_items.order_delivery_address`,
    data?.params,
    config,
    rejectWithValue
  )

export const createOrderService = (data, rejectWithValue, config = {}) =>
  post(
    `${PRODUCTS_BASE_URL}?include[]=order_items.product`,
    data,
    config,
    rejectWithValue
  )

export const getOrderService = (id, params = {}, rejectWithValue) =>
  get(
    `${PRODUCTS_BASE_URL}/${id}`,
    {
      params: params,
    },
    rejectWithValue
  )

export const updateShippingAddressService = (
  id,
  data,
  rejectWithValue,
  config = {}
) => post(`${SHIPPING_ADDRESS}/${id}`, data, config, rejectWithValue)

export const createShippingAddressService = (
  data,
  rejectWithValue,
  config = {}
) => post(SHIPPING_ADDRESS, data, config, rejectWithValue)

export const getOrdersService = (params, rejectWithValue) =>
  get(
    PRODUCTS_BASE_URL,
    {
      params: params,
    },
    rejectWithValue
  )

export const getOrderItemService = (id, params, rejectWithValue) =>
  get(
    `${ORDER}/show/${id}`,
    {
      params: params,
    },
    rejectWithValue
  )

export const updateOrderStatusService = (data, rejectWithValue, config = {}) =>
  post(`${ORDER}/approval-status`, data, config, rejectWithValue)

export const cancelOrderService = (data, rejectWithValue, config = {}) =>
  post(`${ORDER}/update-status`, data, config, rejectWithValue)

export const updateOrderItemStatusService = (
  data,
  rejectWithValue,
  config = {}
) => post(`${ORDERITEM}/update-status`, data, config, rejectWithValue)

export const updateOrderItemsStatusByOrderIDService = (
  data,
  rejectWithValue,
  config = {}
) =>
  post(
    `${ORDERITEM}/update/status?include[]=order_items`,
    data,
    config,
    rejectWithValue
  )

export const getMerchantOrdersService = (params, rejectWithValue) =>
  get(
    `${ORDER}/list-merchant-orders`,
    {
      params: params,
    },
    rejectWithValue
  )

export const getSummaryService = (data, rejectWithValue, config = {}) =>
  post(`${CART}/buy-now`, data, config, rejectWithValue)

export const getListDisbursementService = (params, rejectWithValue) => 
  get(`${LIST_DISBURSEMENT}`, {params: params}, rejectWithValue)

  export const updateDisbursementServiceByOrderId = (
    data,
    rejectWithValue,
  ) =>
    post(
      `${DISBURSEMENT}/update-status`,
      data,
      rejectWithValue
    )