import { combineReducers } from "@reduxjs/toolkit"
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"

//import slices from Redux Toolkit -------------------------------
import { reducer as sampleReduxSliceReducer } from "../slices/sampleReduxSlice"
import { reducer as authenticationSliceReducer } from "slices/authentication"
import { reducer as Agency } from "../slices/Agency"
import { reducer as agencyMyOrdersReducer } from "../slices/agency-my-orders"
import { reducer as agencyNotificationsReducer } from "../slices/agency-notifications"
import { reducer as merchantOrdersReducer } from "../slices/merchant-orders"
import { reducer as merchantNotificationsReducer } from "../slices/merchant-notifications"
import { reducer as Product } from "../slices/Product"
import { reducer as AreaOfDeliveries } from "../slices/AreaOfDeliveries"
import { reducer as EMarketplace } from "../slices/EMarketplace"
import { reducer as ProductReview } from "../slices/ProductReview"

import { orderReducer } from "slices/Order"
import { ewalletReducer } from "slices/Ewallet"
import { categoryReducer } from "slices/Category"
import { authUserReducer } from "slices/AuthUser"
import { adminProductReducer } from "slices/AdminProduct"
import { itemSpecificationReducer } from "slices/ItemSpecification"

//import reducer from PURE Redux -------------------------------
import Ecommerce from "store/e-commerce/reducer"
// Front
import Layout from "../store/layout/reducer"
// Authentication
import Login from "../store/auth/login/reducer"
//Dashboard
import Dashboard from "../store/dashboard/reducer"

// PERSIST REDUCER CONFIG -------------------------------
// const specificReducerConfig = {
//   key: 'singleReducerName',
//   storage,
//   blacklist: ['granularMeansPerStateProperty'] // DONT PERSIST
//   whitelist: ['granularMeansPerStateProperty'] // PERSIST
// }

// const persistedReducer = persistReducer(specificReducerConfig, specificReducerName)

const productReducerConfig = {
  key: "productReducer",
  storage,
  // blacklist: ['create', 'delete']
}

const productReducer = persistReducer(productReducerConfig, Product)

const rootReducer = combineReducers({
  // PURE REDUCER SLICES -------------------
  Layout,
  Login,
  Ecommerce,
  Dashboard,
  // REDUCER SLICES ------------------------
  sampleReduxSlice: sampleReduxSliceReducer,
  authReduxSlice: authenticationSliceReducer,
  agencyNotificationsSlice: agencyNotificationsReducer,
  agencyMyOrdersSlice: agencyMyOrdersReducer,
  Agency,
  merchantOrdersSlice: merchantOrdersReducer,
  merchantNotificationsSlice: merchantNotificationsReducer,
  Product: productReducer,
  Order: orderReducer,
  Category: categoryReducer,
  AuthUser: authUserReducer,
  AdminProduct: adminProductReducer,
  ItemSpecification: itemSpecificationReducer,
  eWallet: ewalletReducer,
  AreaOfDeliveries,
  EMarketplace,
  ProductReview,
  // CUSTOM PERSISTED REDUCERS -------------
  //E.g Persisting per Reducer
  // Agency: persistReducer(specificReducerConfig, specificReducerName),
})

export default rootReducer
