import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import { transformData } from "helpers/dataTransformer"
import {
  getOrderService,
  getOrdersService,
  getSummaryService,
  createOrderService,
  cancelOrderService,
  getOrderItemService,
  updateOrderStatusService,
  getMerchantOrdersService,
  updateOrderAddressService,
  updateOrderItemStatusService,
  updateShippingAddressService,
  createShippingAddressService,
  updateOrderItemsStatusByOrderIDService,
  getListDisbursementService,
  updateDisbursementServiceByOrderId,
} from "services/order.service"

export const createOrder = createAsyncThunk(
  "/createOrder",
  async (data, { rejectWithValue }) => {
    return await createOrderService(data, rejectWithValue, {})
  }
)

export const getOrders = createAsyncThunk(
  "/getOrders",
  async (params, { rejectWithValue }) => {
    return await getOrdersService(params, rejectWithValue)
  }
)

export const getMerchantOrders = createAsyncThunk(
  "/getMerchantOrders",
  async (params, { rejectWithValue }) => {
    return await getMerchantOrdersService(params, rejectWithValue)
  }
)

export const getOrder = createAsyncThunk(
  "/getOrder",
  async ({ id, params = {} }, { rejectWithValue }) => {
    return await getOrderService(id, params, rejectWithValue)
  }
)

export const getOrderStatus = createAsyncThunk(
  "/getOrderStatus",
  async ({ id, params = {} }, { rejectWithValue }) => {
    return await getOrderService(id, params, rejectWithValue)
  }
)

export const getOrderItem = createAsyncThunk(
  "/getOrderItem",
  async ({ id, params = {} }, { rejectWithValue }) => {
    return await getOrderItemService(id, params, rejectWithValue)
  }
)

export const updateShippingAddress = createAsyncThunk(
  "/updateShippingAddress",
  async ({ data, id, config = {} }, { rejectWithValue }) => {
    return await updateShippingAddressService(id, data, rejectWithValue, config)
  }
)

export const createShippingAddress = createAsyncThunk(
  "/createShippingAddress",
  async (data, { rejectWithValue }) => {
    return await createShippingAddressService(data, rejectWithValue, {})
  }
)

export const updateOrderStatus = createAsyncThunk(
  "/updateOrderStatus",
  async ({ data, config = {} }, { rejectWithValue }) => {
    return await updateOrderStatusService(data, rejectWithValue, config)
  }
)

export const cancelOrder = createAsyncThunk(
  "/cancelOrder",
  async ({ data, config = {} }, { rejectWithValue }) => {
    return await cancelOrderService(data, rejectWithValue, config)
  }
)

export const updateOrderItemStatus = createAsyncThunk(
  "/updateOrderItemStatus",
  async ({ data, config = {} }, { rejectWithValue }) => {
    return await updateOrderItemStatusService(data, rejectWithValue, config)
  }
)

export const updateOrderItemsStatusByOrderID = createAsyncThunk(
  "/updateOrderItemsStatusByOrderID",
  async ({ data, config = {} }, { rejectWithValue }) => {
    return await updateOrderItemsStatusByOrderIDService(
      data,
      rejectWithValue,
      config
    )
  }
)

export const getSummary = createAsyncThunk(
  "/getSummary",
  async ({ params = {} }, { rejectWithValue }) => {
    return await getSummaryService(params, rejectWithValue, {})
  }
)

export const updateOrderAddress = createAsyncThunk(
  "/updateOrderAddress",
  async ({ data, config = {} }, { rejectWithValue }) => {
    return await updateOrderAddressService(data, rejectWithValue, config)
  }
)

export const getListDisbursement = createAsyncThunk(
  "/getListDisbursement",
  async (params, { rejectWithValue }) => {
    return await getListDisbursementService(params, rejectWithValue)
  }
)

export const updateDisbursementServicePaymentStatusByAdmin = createAsyncThunk(
  "/updateDisbursementServicePaymentStatusByAdmin",
  async ({ data }, { rejectWithValue }) => {
    console.log(rejectWithValue)
    return await updateDisbursementServiceByOrderId(data, rejectWithValue)
  }
)

const initialLog = {
  type: "",
  error: false,
  message: null,
  loading: false,
  success: false,
}

const initialState = {
  orders: [],
  order: {},
  included: [],
  disbursement: [],
  summary: {},
  otherData: {},
  logs: initialLog,
  currentOrderStatus: null,
}

const slice = createSlice({
  name: "Order",
  initialState,
  reducers: {
    handleToggleProductActionType(state, action) {
      state.actionType = action.payload
    },
    resetData(state, action) {
      state = initialState
    },
  },
  extraReducers: {
    [cancelOrder.pending]: state => {
      state.logs = {
        ...initialLog,
        loading: true,
        type: "cancelOrder",
      }
    },
    [cancelOrder.fulfilled]: (state, action) => {
      state.logs = {
        ...state.logs,
        success: true,
        loading: false,
      }
    },
    [cancelOrder.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
        message: action?.payload?.errors
          ? action?.payload?.errors[0]?.detail
          : "Something went wrong",
      }
    },
    [updateOrderAddress.pending]: state => {
      state.logs = {
        ...initialLog,
        loading: true,
        type: "updateOrderAddress",
      }
    },
    [updateOrderAddress.fulfilled]: (state, action) => {
      state.logs = {
        ...state.logs,
        success: true,
        loading: false,
      }
    },
    [updateOrderAddress.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
        message: action?.payload?.errors
          ? action?.payload?.errors[0]?.detail
          : "Something went wrong",
      }
    },
    [getSummary.pending]: state => {
      state.logs = {
        ...initialLog,
        loading: true,
        type: "getSummary",
      }
    },
    [getSummary.fulfilled]: (state, action) => {
      state.logs = {
        ...state.logs,
        success: true,
        loading: false,
      }
      state.summary = transformData(action.payload)
      state.otherData = action.payload.other_data
    },
    [getSummary.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
        message: action?.payload?.errors
          ? action?.payload?.errors[0]?.detail
          : "Something went wrong",
      }
    },
    [createOrder.pending]: state => {
      state.logs = { ...initialLog, loading: true, type: "createOrder" }
    },
    [createOrder.fulfilled]: (state, action) => {
      state.logs = {
        ...state.logs,
        success: true,
        loading: false,
      }
    },
    [createOrder.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
        message: action?.payload?.errors
          ? `(${action?.payload?.errors[0]?.code}) ${action?.payload?.errors[0]?.detail}`
          : "Something went wrong",
      }
    },
    [getOrder.pending]: state => {
      state.logs = { ...initialLog, loading: true, type: "getOrder" }
    },
    [getOrder.fulfilled]: (state, action) => {
      state.order = transformData(action.payload)
      state.included = action.payload.result.included
      state.logs = { ...state.logs, success: true, loading: false }
    },
    [getOrder.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
      }
    },
    [getOrderStatus.pending]: state => {
      state.logs = { ...initialLog, loading: true, type: "getOrderStatus" }
    },
    [getOrderStatus.fulfilled]: (state, action) => {
      const transformedData = transformData(action.payload)
      state.currentOrderStatus = transformedData
    },
    [getOrderStatus.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
      }
    },
    [getOrderItem.pending]: state => {
      state.logs = { ...initialLog, loading: true, type: "getOrderItem" }
    },
    [getOrderItem.fulfilled]: (state, action) => {
      state.order = transformData(action.payload)
      state.included = action.payload.result.included
      state.logs = { ...state.logs, success: true, loading: false }
    },
    [getOrderItem.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
      }
    },
    [getOrders.pending]: state => {
      state.logs = { ...initialLog, loading: true, type: "getOrders" }
      state.orders = []
    },
    [getOrders.fulfilled]: (state, action) => {
      state.orders = transformData(action.payload)
      state.included = action.payload.result.included
      state.logs = { ...state.logs, success: true, loading: false }
    },
    [getOrders.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
        message: action?.payload?.errors ? (
          <>
            <p style={{ fontWeight: 600 }}>{"Loading Table Data Failed. "}</p>
            <p>{`${action?.payload?.errors[0]?.code}. ${action?.payload?.errors[0]?.detail}`}</p>
          </>
        ) : action?.payload === undefined ? (
          <>
            <p style={{ fontWeight: 600 }}>{"Loading Table Data Failed. "}</p>
            <p>
              {
                "404/500/503. Server Error or Resource not found due to API response undefined."
              }
            </p>
          </>
        ) : (
          "Something went wrong"
        ),
      }
    },
    [getMerchantOrders.pending]: state => {
      state.logs = { ...initialLog, loading: true, type: "getMerchantOrders" }
    },
    [getMerchantOrders.fulfilled]: (state, action) => {
      state.orders = transformData(action.payload)
      state.included = action.payload.result.included
      state.logs = { ...state.logs, success: true, loading: false }
    },
    [getMerchantOrders.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
      }
    },
    [updateShippingAddress.pending]: state => {
      state.logs = {
        ...initialLog,
        loading: true,
        type: "updateShippingAddress",
      }
    },
    [updateShippingAddress.fulfilled]: (state, action) => {
      state.logs = {
        ...state.logs,
        success: true,
        loading: false,
      }
    },
    [updateShippingAddress.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
        message: action?.payload?.errors
          ? action?.payload?.errors[0]?.detail
          : "Something went wrong",
      }
    },
    [createShippingAddress.pending]: state => {
      state.logs = {
        ...initialLog,
        loading: true,
        type: "createShippingAddress",
      }
    },
    [createShippingAddress.fulfilled]: (state, action) => {
      state.logs = {
        ...state.logs,
        success: true,
        loading: false,
      }
    },
    [createShippingAddress.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
        message: action?.payload?.errors
          ? action?.payload?.errors[0]?.detail
          : "Something went wrong",
      }
    },
    [updateOrderStatus.pending]: state => {
      state.logs = {
        ...initialLog,
        loading: true,
        type: "updateOrderStatus",
      }
    },
    [updateOrderStatus.fulfilled]: (state, action) => {
      state.logs = {
        ...state.logs,
        success: true,
        loading: false,
      }
    },
    [updateOrderStatus.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
        message: action?.payload?.errors
          ? action?.payload?.errors[0]?.detail
          : "Something went wrong",
      }
    },
    [updateOrderItemStatus.pending]: state => {
      state.logs = {
        ...initialLog,
        loading: true,
        type: "updateOrderItemStatus",
      }
    },
    [updateOrderItemStatus.fulfilled]: (state, action) => {
      state.logs = {
        ...state.logs,
        success: true,
        loading: false,
      }
    },
    [updateOrderItemStatus.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
        message: action?.payload?.errors
          ? action?.payload?.errors[0]?.detail
          : "Something went wrong",
      }
    },
    [updateOrderItemsStatusByOrderID.pending]: state => {
      state.logs = {
        ...initialLog,
        loading: true,
        type: "updateOrderItemsStatusByOrderID",
      }
    },
    [updateOrderItemsStatusByOrderID.fulfilled]: (state, action) => {
      state.logs = {
        ...state.logs,
        success: true,
        loading: false,
      }
    },
    [updateOrderItemsStatusByOrderID.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
        message: action?.payload?.errors
          ? action?.payload?.errors[0]?.detail
          : "Something went wrong",
      }
    },
    [getListDisbursement.pending]: state => {
      state.logs = { ...initialLog, loading: true, type: "getListDisbursement" }
    },
    [getListDisbursement.fulfilled]: (state, action) => {
      state.disbursement = transformData(action.payload)
      console.log(transformData(action.payload))
      state.included = action.payload.result.included
      state.logs = { ...state.logs, success: true, loading: false }
    },
    [getListDisbursement.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
      }
    },
    [updateDisbursementServicePaymentStatusByAdmin.pending]: state => {
      state.logs = { ...initialLog, loading: true, type: "updateDisbursementServicePaymentStatusByAdmin" }
    },
    [updateDisbursementServicePaymentStatusByAdmin.fulfilled]: (state, action) => {
      state.logs = {
        ...state.logs,
        success: true,
        loading: false,
      }
    },
    [updateDisbursementServicePaymentStatusByAdmin.rejected]: (state, action) => {
      console.log(action)
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
        message: action?.payload?.errors
          ? action?.payload?.errors[0]?.detail
          : "Something went wrong",
      }
    },
    ["PURGE"]: () => {
      return initialState
    },
  },
})

export const { actions: orderActions, reducer: orderReducer } = slice

export const { resetData } = orderActions

export default slice
