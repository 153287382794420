import React from "react"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import {
  getActiveOrdersService,
  getActiveOrdersByOrderIdService,
  updateActiveOrderService,
  updateActiveOrderItemService,
  updateActiveOrderItemServiceFormData,
  getOrderHistoryService,
} from "services/merchant-orders.service"
import { transformData } from "helpers/dataTransformer"

export const getMerchantActiveOrders = createAsyncThunk(
  "getMerchantActiveOrders",
  async (params, { rejectWithValue }) => {
    return await getActiveOrdersService(params, rejectWithValue)
  }
)

export const getMerchantActiveOrdersByOrderId = createAsyncThunk(
  "getMerchantActiveOrdersByOrderId",
  async ({ id, params = {} }, { rejectWithValue }) => {
    return await getActiveOrdersByOrderIdService(id, params, rejectWithValue)
  }
)

export const getMerchantOrderHistory = createAsyncThunk(
  "getMerchantOrderHistory",
  async (params, { rejectWithValue }) => {
    return await getOrderHistoryService(params, rejectWithValue)
  }
)

export const updateMerchantActiveOrders = createAsyncThunk(
  "updateMerchantActiveOrders",
  async ({ data, orderId, config = {} }, { rejectWithValue }) => {
    return await updateActiveOrderService(
      orderId,
      data,
      rejectWithValue,
      config
    )
  }
)

export const updateMerchantActiveOrderItem = createAsyncThunk(
  "updateMerchantActiveOrderItem",
  async ({ data, orderId, config = {} }, { rejectWithValue }) => {
    return await updateActiveOrderItemService(
      orderId,
      data,
      rejectWithValue,
      config
    )
  }
)

export const updateMerchantActiveOrderItemFormData = createAsyncThunk(
  "updateMerchantActiveOrderItemFormData",
  async (data, { rejectWithValue }) => {
    return await updateActiveOrderItemServiceFormData(data, rejectWithValue, {})
  }
)

const initialLog = {
  type: "",
  error: false,
  message: null,
  loading: false,
  success: false,
}

const initialState = {
  merchantActiveOrdersArr: [],
  merchantActiveViewOrderArr: [],
  merchantOrderHistoryArr: [],
  logs: initialLog,
}

const slice = createSlice({
  name: "merchantOrders",
  initialState,
  reducers: {
    resetMerchantActiveOrdersState: state => {
      state.merchantActiveOrdersArr = []
      state.logs = initialLog
    },
    resetMerchantOrderHistoryState: state => {
      state.merchantOrderHistoryArr = []
      state.logs = initialLog
    },
  },
  extraReducers: {
    [getMerchantActiveOrders.pending]: state => {
      state.logs = {
        ...initialLog,
        loading: true,
        type: "getMerchantActiveOrders",
      }
    },
    [getMerchantActiveOrders.fulfilled]: (state, action) => {
      // state.myOrders = action.payload.result.data
      // state.included = action.payload.result?.included
      state.merchantActiveOrdersArr = transformData(action.payload)
      state.logs = { ...state.logs, success: true, loading: false }
    },
    [getMerchantActiveOrders.rejected]: (state, action) => {
      // console.log("getMerchantError: ", action)
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
        message: action?.payload?.errors ? (
          <>
            <p style={{ fontWeight: 600 }}>{"Loading Table Data Failed. "}</p>
            <p>{`${action?.payload?.errors[0]?.code}. ${action?.payload?.errors[0]?.detail}`}</p>
          </>
        ) : action?.payload === undefined ? (
          <>
            <p style={{ fontWeight: 600 }}>{"Loading Table Data Failed. "}</p>
            <p>
              {
                "404/500/503. Server Error or Resource not found due to API response undefined."
              }
            </p>
          </>
        ) : (
          "Something went wrong"
        ),
      }
    },
    [getMerchantActiveOrdersByOrderId.pending]: state => {
      state.logs = {
        ...initialLog,
        loading: true,
        type: "getMerchantActiveOrdersByOrderId",
      }
    },
    [getMerchantActiveOrdersByOrderId.fulfilled]: (state, action) => {
      // state.myOrders = action.payload.result.data
      // state.included = action.payload.result?.included
      state.merchantActiveViewOrderArr = transformData(action.payload)
      state.logs = { ...state.logs, success: true, loading: false }
    },
    [getMerchantActiveOrdersByOrderId.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
        message: action?.payload?.errors ? (
          <>
            <p style={{ fontWeight: 600 }}>{"Loading Table Data Failed. "}</p>
            <p>{`${action?.payload?.errors[0]?.code}. ${action?.payload?.errors[0]?.detail}`}</p>
          </>
        ) : (
          "Something went wrong"
        ),
      }
    },
    [getMerchantOrderHistory.pending]: state => {
      state.logs = {
        ...initialLog,
        loading: true,
        type: "getMerchantOrderHistory",
      }
    },
    [getMerchantOrderHistory.fulfilled]: (state, action) => {
      // state.myOrders = action.payload.result.data
      // state.included = action.payload.result?.included
      console.log(
        "TRANFORMED DATA getMerchantOrderHistory",
        transformData(action.payload)
      )
      state.merchantOrderHistoryArr = transformData(action.payload)
      state.logs = { ...state.logs, success: true, loading: false }
    },
    [getMerchantOrderHistory.rejected]: (state, action) => {
      // console.log("getMerchantError: ", action)
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
        message: action?.payload?.errors ? (
          <>
            <p style={{ fontWeight: 600 }}>{"Loading Table Data Failed. "}</p>
            <p>{`${action?.payload?.errors[0]?.code}. ${action?.payload?.errors[0]?.detail}`}</p>
          </>
        ) : action?.payload === undefined ? (
          <>
            <p style={{ fontWeight: 600 }}>{"Loading Table Data Failed. "}</p>
            <p>
              {
                "404/500/503. Server Error or Resource not found due to API response undefined."
              }
            </p>
          </>
        ) : (
          "Something went wrong"
        ),
      }
    },
    [updateMerchantActiveOrders.pending]: state => {
      state.logs = {
        ...initialLog,
        loading: true,
        type: "updateMerchantActiveOrders",
      }
    },
    [updateMerchantActiveOrders.fulfilled]: (state, action) => {
      state.logs = {
        ...state.logs,
        success: true,
        loading: false,
      }
    },
    [updateMerchantActiveOrders.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
        message: action?.payload?.errors
          ? action?.payload?.errors[0]?.detail
          : "Something went wrong",
      }
    },
    [updateMerchantActiveOrderItem.pending]: state => {
      state.logs = {
        ...initialLog,
        loading: true,
        type: "updateMerchantActiveOrderItem",
      }
    },
    [updateMerchantActiveOrderItem.fulfilled]: (state, action) => {
      state.logs = {
        ...state.logs,
        success: true,
        loading: false,
      }
    },
    [updateMerchantActiveOrderItem.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
        message: action?.payload?.errors
          ? `${
              action?.payload?.errors[0]?.detail ===
              "Undefined property: stdClass::$fname"
                ? "404"
                : action?.payload?.errors[0]?.code
            }. ${
              action?.payload?.errors[0]?.detail ===
              "Undefined property: stdClass::$fname"
                ? "Data Error. Agency doesn't exist in mGeps"
                : action?.payload?.errors[0]?.detail
            }`
          : "Something went wrong",
      }
    },
    [updateMerchantActiveOrderItemFormData.pending]: state => {
      state.logs = {
        ...initialLog,
        loading: true,
        type: "updateMerchantActiveOrderItemFormData",
      }
    },
    [updateMerchantActiveOrderItemFormData.fulfilled]: (state, action) => {
      state.logs = {
        ...state.logs,
        success: true,
        loading: false,
      }
    },
    [updateMerchantActiveOrderItemFormData.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
        message: action?.payload?.errors
          ? `${
              action?.payload?.errors[0]?.detail ===
              "Undefined property: stdClass::$fname"
                ? "404"
                : action?.payload?.errors[0]?.code
            }. ${
              action?.payload?.errors[0]?.detail ===
              "Undefined property: stdClass::$fname"
                ? "Data Error. Agency doesn't exist in mGeps"
                : action?.payload?.errors[0]?.detail
            }`
          : "Something went wrong",
      }
    },
    ["PURGE"]: () => {
      return initialState
    },
  },
})

export const { reducer } = slice

export const resetMerchantActiveOrdersStateThunk = () => dispatch => {
  dispatch(slice.actions.resetMerchantActiveOrdersState())
}
export const resetMerchantOrderHistoryStateThunk = () => dispatch => {
  dispatch(slice.actions.resetMerchantOrderHistoryState())
}

export default slice
